/*
  DESKTOP NAVIGATION
*/

.nav__submenu-container {
}

.nav__submenu-header {
	font-size: 16px;
	color: #29343e;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 20px;
}

.nav__submenu-container ul {
	display: block !important;
	padding: 0 !important;
	margin: 0 !important;
}

.nav__submenu-container ul li {
	display: block !important;
	margin-bottom: 8px !important;
	line-height: normal !important;
	margin-left: 0 !important;
}

.nav__submenu-container ul li:last-child {
	margin-bottom: 0px !important;
}

.nav__submenu-container ul li a {
	color: #29343e !important;
	text-decoration: none !important;
	font-weight: 400 !important;
}

.nav__submenu-container ul li a:hover {
	text-decoration: underline !important;
}
