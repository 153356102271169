.footer {
  color: white;
  background: #29343e;
}

.footer__logo {
  font-weight: 700;
  font-size: 24px;
  color: #fff;
}

.social {
  color: #fff !important;
}

.hr {
  border-bottom: 1px solid #3d4852;
}

.legal__text {
  font-size: 12px;
}

.list__container {
}

.list__container ul {
  margin: 18px 0 0 0;
  padding: 0;
  list-style-type: none;
}

.list__container ul li a {
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  text-decoration: none;
}

.list__container ul li a:hover {
  text-decoration: underline;
}

.links__header {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.footer__link {
  color: white;
}
