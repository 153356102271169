.panel {
	padding: 30px;
	background: white;
	position: relative;
	z-index: 15;
	border-radius: 10px;
}

.panel__icon {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: #f3f3f3;
	display: flex;
	align-items: center;
	justify-content: center;
}
.panel__title {
	font-size: 32px;
	color: black;
}
.panel__text {
	font-size: 15px;
	margin-bottom: 0;
}
.panel-dots {
	width: 102px;
	height: 92px;
	top: -30px;
	position: absolute;
	right: -33px;
	z-index: 5;
}
