.li__container {
  display: flex;
  flex-grow: 1;
}

.item__container {
  display: flex;
  flex-grow: 1;
  margin-left: 25px;
  margin-right: 25px;
}

.li__container:hover {
  cursor: pointer;
  text-decoration: underline;
}

.active__tab {
  text-decoration: underline;
  color: #fff !important;
  background-color: #29343e !important;
}

.item__label {
  color: inherit;
  text-decoration: none;
}

.item__icon {
  color: inherit;
  margin-left: auto;
}
