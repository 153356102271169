.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-weight: 400;
}

.h1 {
	font-size: 43px;
}

.h2 {
	font-size: 32px;
}

.h3 {
	font-size: 24px;
}

.h4 {
	font-size: 18px;
}

.h5 {
	font-size: 16px;
}

.h6 {
	font-size: 12px;
}

.bold {
	font-weight: 700 !important;
}
